import React from "react";

import Layout from "components/Layout"
import { Container } from "components/Container";

const PrivacyPolicyPage = () => {

  return (
    <Layout>
    <Container>
      <div className="relative bg-white mb-10 gap-y-5 grid">
        <h1 className="mb-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</h1>
        <p>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a donation from armandhurford.com (the "Site").
        </p>

        <h3>PERSONAL INFORMATION WE COLLECT</h3>
        <p>
          When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information."
        </p>
        <p>
          We collect Device Information using the following technologies:
        </p>
        <ul>
          <li>"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.</li>
          <li>"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
          <li>"Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse the Site.</li>
        </ul>
        <p>
          Additionally when you make a donation or attempt to make a donation through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as "Donation Information."
        </p>
        <p>
          When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device Information and Donation Information.
        </p>

        <h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>
        <p>
          We use the Donation Information that we collect generally to fulfill any donations placed through the Site (including processing your payment information)Additionally, we use this Donation Information to: Communicate with you; and Screen our donations for potential risk or fraud;
        </p>
        <p>
          We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our visitors browse and interact with the Site).
        </p>

        <h3>SHARING YOUR PERSONAL INFORMATION</h3>
        <p>
          We share your Personal Information with third parties to help us use your Personal Information, as described above.
        </p>
        <p>
          We use Google Analytics to help us understand how our visitors use the Site--you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>.  You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
        </p>
        <p>
          We collect data necessary to process your payment if you make donations, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/en-ca/privacy">https://stripe.com/en-ca/privacy</a>
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </p>

        <h3>DATA RETENTION</h3>
        <p>
          When you make a donation through the Site, we will maintain your Donation Information for our records unless and until you ask us to delete this information. Donation Informantion will be submitted to BC Elections in accorance with the BC <a href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/96106_10">Election Act</a>.
        </p>

        <h3>MINORS</h3>
        <p>
          The Site is not intended for individuals under the age of 18.
        </p>

        <h3>CHANGES</h3>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
        </p>

        <h3>CONTACT US</h3>

        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:info@armandhurford.com">info@armandhurford.com</a>
        </p>

        <h3></h3>
        <p>
        </p>
      </div>
    </Container>
  </Layout>
  )
}

export default PrivacyPolicyPage
export { Head } from "components/Head"
